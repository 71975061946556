<template>
  <screen_width_cont>
    <div class="myBodyGeneral">
      <div
        class="myHeader_vertical"
        ref="myHeader_destinatariosDetalle"
        id="myHeader_destinatariosDetalle"
      >
        <div class="cont_300_basico ancho_acotado">
          <div class="header_top">
            <headerBack @back="backLocal" />
          </div>
        </div>

        <div class="cont_300_basico ancho_acotado">
          <div class="destinatariosDetalleTitle title">{{ nombre }}</div>
        </div>
      </div>

      <blurComp
        class="blur_sup"
        :style="blur_sup_destinatariosDetalle"
        :scroll_to_="scroll_to_top"
        tipo="sup"
      />

      <div
        class="contenido centrado"
        :style="contenido_destinatariosDetalle"
        v-show="cont_visible"
        @scroll="onScroll"
        ref="contenido_destinatariosDetalle"
      >
        <div class="cont_300_basico ancho_acotado">
          <div class="destinatariosDetalleBody">
            <div class="destinatariosDetalleEncabezado1_line">
              <div class="destinatariosDetalleEncabezado1">
                {{ t("app.destinatario_detalle.datos_personales") }}
              </div>
              <div class="destinatariosDetalleEncabezado1_icons">
                <div
                  class="desti_detayl_icon desti_detayl_icon_rigth"
                  @click="callDestiPersonalDataForm('eliminar')"
                >
                  <editar_eliminar
                    type="eliminar"
                    formato="icon"
                    ancho_img="1.4"
                    font_size_text="10"
                  />
                </div>
                <div
                  class="desti_detayl_icon"
                  @click="callDestiPersonalDataForm('editar')"
                >
                  <editar_eliminar
                    type="editar"
                    formato="icon"
                    ancho_img="1.2"
                    font_size_text="10"
                  />
                </div>
              </div>
            </div>

            <div
              class="destinatariosDetalleRow"
              v-for="(item, i) in destinatarioDatos"
              :key="i"
            >
              <cardLista :item="item" />
            </div>
          </div>

          <div class="destinatariosDetalleHr"></div>

          <div class="destinatariosDetalleEncabezado1">
            {{ t("app.destinatario_detalle.medio_entrega") }}
          </div>
          <div class="destinatariosDetalleBody2">
            <div
              class="destinatariosDetalleRow2"
              v-for="(medioEntrega, w) in destMediosEntrega"
              :key="w"
            >
              <cardMisDatosDestMedioEntrega
                :medioEntrega="medioEntrega"
                @callEditFormaEntregaForm="callEditFormaEntregaFormLocal"
                @changeFEDespliegue="changeFEDespliegueLocal"
                :idFEDesplegado="idFEDesplegado"
                @delete_FormaEntrega="delete_FormaEntrega"
              />
            </div>
          </div>
        </div>
      </div>

      <blurComp
        class="blur_inf"
        :style="blur_inf_destinatariosDetalle"
        :scroll_to_="scroll_to_bottom"
        tipo="inf"
      />

      <div class="footer" ref="footer_destinatariosDetalle">
        <div
          class="destDetNuevoMedioEntrega text_basico f_w_500"
          @click="callEditNewFEFormLocal"
        >
          +&nbsp;{{ t("app.destinatario_detalle.nuevo_medio_entrega") }}
        </div>

        <div class="footer_env_seguro">
          <enviosSeguros @callEnvioSeguroModal_="callEnvioSeguroModal" />
        </div>
      </div>
    </div>
  </screen_width_cont>
</template>

<script>
import headerBack from "../components/generales/headerBack.vue";
import { modalController } from "@ionic/vue";
import { useI18n } from "vue-i18n";
import { mapActions, mapMutations, mapState } from "vuex";
import cardMisDatosDestMedioEntrega from "../components/destinatario/cardMisDatosDestMedioEntrega.vue";
import envioSeguroHome from "./modales/enviosSegurosHome.vue";
import enviosSeguros from "../components/generales/envios_seguros.vue";
import cardLista from "../components/generales/card_lista_2.vue";
import estas_seguro from "./modales/estas_seguro.vue";
import blurComp from "../components/generales/blur.vue";
import {
  blur_sup_scroll,
  blur_inf_scroll,
  contenido_style,
  blur_sup_style,
  blur_inf_style,
} from "../assets/funciones/blur";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";
import editar_eliminar from "../components/generales/editar_eliminar.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },

  name: "destinatarioFEForm",
  components: {
    headerBack,
    cardMisDatosDestMedioEntrega,
    enviosSeguros,
    cardLista,
    blurComp,
    screen_width_cont,
    editar_eliminar,
  },
  data() {
    return {
      idFEDesplegado: "-1",
      header: 0,
      footer: 0,
      cont_visible: false,
      scroll_to_bottom: true,
      scroll_to_top: true,
      contenido_destinatariosDetalle: {},
      blur_sup_destinatariosDetalle: {},
      blur_inf_destinatariosDetalle: {},
    };
  },
  computed: {
    ...mapState([
      "menuSpDesde",
      "remitente",
      "configuracion",
      "dataDestinatarioSelected",
      "heigthScreen",
      "widthScreen",
      "resp_borrar_destinatario",
      "plataforma",
      "resp_borrar_forma_entega",
    ]),

    destinatarioDatos() {
      let aux = [];
      if (
        this.dataDestinatarioSelected &&
        Object.keys(this.dataDestinatarioSelected).length > 0 &&
        this.configuracion &&
        this.configuracion.reglasFormularioDestinatario
      ) {
        this.configuracion.reglasFormularioDestinatario.forEach((item) => {
          if (item[15]["res"] && item[15]["res"][0] == "si") {
            let destProperties = Object.keys(this.dataDestinatarioSelected);
            destProperties.forEach((propiedad) => {
              if (item[4] == propiedad) {
                if (item[7] == "selectPais") {
                  let aux3 =
                    this.configuracion.pais[
                      this.dataDestinatarioSelected[propiedad]
                    ].nombre;
                  aux.push([item[4], aux3]);
                } else if (item[7] == "select") {
                  let aux3 = this.configuracion.pais[
                    this.dataDestinatarioSelected.pais_moneda_destino_id
                  ][propiedad].findIndex((dato) => {
                    return dato[0] == this.dataDestinatarioSelected[propiedad];
                  });

                  aux.push([
                    item[4],
                    this.configuracion.pais[
                      this.dataDestinatarioSelected.pais_moneda_destino_id
                    ][propiedad][aux3][1],
                  ]);
                } else {
                  aux.push([item[4], this.dataDestinatarioSelected[propiedad]]);
                }
              }
            });
          }
        });
      }
      // console.log(aux, this.dataDestinatarioSelected);
      return aux;
    },
    nombre() {
      let aux = "";
      if (this.dataDestinatarioSelected) {
        aux = `${this.dataDestinatarioSelected.nombre1} ${this.dataDestinatarioSelected.nombre2} ${this.dataDestinatarioSelected.apellido1} ${this.dataDestinatarioSelected.apellido2}`;
      }
      return aux;
    },
    destMediosEntrega() {
      let aux = [];
      if (
        this.dataDestinatarioSelected &&
        this.dataDestinatarioSelected.detalle_entrega
      ) {
        let aux2 = Object.entries(
          this.dataDestinatarioSelected.detalle_entrega
        );
        aux2.forEach((element) => {
          if (element[0] != "0") {
            element[1].id = element[0];
            aux.push(element[1]);
          }
        });
      }

      return aux;
    },
  },
  methods: {
    ...mapMutations([
      "updateProcesoGeneral",
      "updateDataDestinSelected",
      "updateTransaccion",
      "updateBloquearNavegacion",
      "updateDataDestinatarioSelected",
    ]),
    ...mapActions(["destinatario_borrar", "eliminar_forma_entrega"]),

    backLocal() {
      this.$router.push({ name: "destinatarios", replace: true });
    },
    callDestiPersonalDataForm(tipo_action) {
      if (tipo_action == "editar") {
        this.updateDataDestinSelected({
          tipo: "datos_personales",
          data: this.dataDestinatarioSelected,
        });
        this.$router.push({ name: "destinMyDataForm", replace: true });
      } else if (tipo_action == "eliminar") {
        this.consultar_accion();
      }
    },
    async consultar_accion() {
      this.updateBloquearNavegacion(true);

      let cssClass = ["myModalAbandonar"];

      if (this.widthScreen < 400) {
        cssClass.push("myModalAbandonar_ancho_90");
      } else if (this.widthScreen < 600) {
        cssClass.push("myModalAbandonar_ancho_80");
      } else if (this.widthScreen < 800) {
        cssClass.push("myModalAbandonar_ancho_70");
      } else {
        cssClass.push("myModalAbandonar_ancho_50");
      }

      if (this.heigthScreen < 640) {
        cssClass.push("myModalAbandonar_alto_90");
      } else if (this.heigthScreen < 800) {
        cssClass.push("myModalAbandonar_alto_60");
      } else if (this.heigthScreen < 900) {
        cssClass.push("myModalAbandonar_alto_50");
      }

      const modal = await modalController.create({
        component: estas_seguro,
        componentProps: {
          title: this.t("app.destinatario_detalle.seguro_borrar_dest"),
          color_title: "rojo",
          color_btn_1: "rojo",
          color_btn_2: "gris",
          text: this.nombre,
          btnText_1: this.t("app.destinatario_detalle.eliminar"),
          btnText_2: "",
          colorIcon: "rojoOscuro",
        },
        cssClass: cssClass,

        animated: true,
        backdropDismiss: false,
      });

      modal.onDidDismiss().then((data) => {
        this.updateBloquearNavegacion(false);
        if (
          data &&
          data.data &&
          data.data.action &&
          data.data.action == "action_1"
        ) {
          console.log("Eliminar destinatario");
          this.destinatario_borrar(this.dataDestinatarioSelected.id);
        } else if (
          data &&
          data.data &&
          data.data.action &&
          data.data.action == "action_2"
        ) {
          // this.$router.push({ name: "nuevoDestinatarioForm" });
        }
      });

      return modal.present();
    },
    callEditFormaEntregaFormLocal(index) {
      // console.log(index, this.destMediosEntrega[index]);
      this.updateDataDestinSelected({
        tipo: "forma_entrega",
        data: this.dataDestinatarioSelected,
        indexFE: index,
      });
      this.$router.push({ name: "destinMyDataForm", replace: true });
    },
    callEditNewFEFormLocal() {
      // console.log(index, this.destMediosEntrega[index]);
      this.updateDataDestinSelected({
        tipo: "nueva_forma_entrega",
        data: this.dataDestinatarioSelected,
        indexFE: 0,
      });
      this.$router.push({ name: "destinMyDataForm", replace: true });
    },
    changeFEDespliegueLocal(index) {
      this.idFEDesplegado = index;
    },
    async delete_FormaEntrega(id_FE) {
      console.log(id_FE, this.dataDestinatarioSelected);
      let fe = this.t(
        `variables.forma_entrega_id.${
          this.dataDestinatarioSelected.pais_moneda_destino_id.split("_")[0]
        }.${
          this.dataDestinatarioSelected.detalle_entrega[id_FE].forma_entrega_id
        }`
      );
      // let fe =
      //   this.dataDestinatarioSelected.detalle_entrega[id_FE].forma_entrega_id == "1"
      //     ?  "Transferencia a cuenta"
      //     : this.dataDestinatarioSelected.detalle_entrega[id_FE].forma_entrega_id ==
      //       "2"
      //     ? "Entrega en caja"
      //     : "";

      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: estas_seguro,
        componentProps: {
          title: this.t("app.destinatario_detalle.seguro_borrar_fe"),
          color_title: "rojo",
          color_btn_1: "rojo",
          color_btn_2: "gris",
          text: `${this.t(
            "app.destinatario_detalle.borrar_fr_t1"
          )}  ${fe} ${this.t("app.destinatario_detalle.borrar_fr_t2")}`,
          btnText_1: this.t("app.comunes.btns.eliminar"),
          btnText_2: "", //Volver
          colorIcon: "rojoOscuro",
          // ancho: "250"
        },
        cssClass:
          this.widthScreen < 600
            ? [
                "myModalAbandonar",
                "myModalAbandonar_ancho_80",
                "myModalAbandonar_alto_50",
              ]
            : [
                "myModalAbandonar",
                "myModalAbandonar_ancho_40",
                "myModalAbandonar_alto_40",
              ],
        // showBackdrop: false,
        animated: true,
        backdropDismiss: false,
        // swipeToClose: true,
        // mode: "ios",
      });

      modal.onDidDismiss().then((data) => {
        this.updateBloquearNavegacion(false);
        if (
          data &&
          data.data &&
          data.data.action &&
          data.data.action == "action_1"
        ) {
          console.log("Eliminar FORMA de ENTREGA");
          // console.log(this.idFEDesplegado, this.dataDestinatarioSelected.id);
          this.eliminar_forma_entrega({
            forma_entrega_id: this.idFEDesplegado,
            destinatario_id: this.dataDestinatarioSelected.id,
          });
        } else if (
          data &&
          data.data &&
          data.data.action &&
          data.data.action == "action_2"
        ) {
          // this.$router.push({ name: "nuevoDestinatarioForm" });
        }
      });

      return modal.present();
    },
    async callEnvioSeguroModal() {
      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: envioSeguroHome,
        // cssClass: 'my-custom-class',
        // componentProps: {
        //   dataTransfer: data
        // },
      });
      modal.onDidDismiss().then(() => {
        this.updateBloquearNavegacion(false);
      });
      return modal.present();
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      this.scroll_to_top = blur_sup_scroll(scrollTop);
      this.scroll_to_bottom = blur_inf_scroll(
        scrollTop,
        clientHeight,
        scrollHeight
      );
    },
  },
  watch: {
    heigthScreen() {
      this.contenido_destinatariosDetalle = contenido_style(
        this.heigthScreen,
        this.header,
        this.footer,
        this.plataforma == "web" ? 63 : 0
      );
    },
    resp_borrar_destinatario() {
      if (this.resp_borrar_destinatario.status) {
        if (this.resp_borrar_destinatario.resp) {
          this.$router.push({ name: "destinatarios", replace: true });
        }
      }
    },
    resp_borrar_forma_entega() {
      if (this.resp_borrar_forma_entega.status) {
        if (this.resp_borrar_forma_entega.resp) {
          this.$router.push({ name: "destinatarios", replace: true });
        }
      }
    },
  },
  mounted() {},
  ionViewWillEnter() {
    // console.log(this.remitente.destinatarios, this.dataDestinatarioSelected);
    this.updateProcesoGeneral("misDatos");
    this.updateTransaccion({
      pais_moneda_destino_id:
        this.dataDestinatarioSelected.pais_moneda_destino_id,
    });
    this.updateDataDestinatarioSelected(
      this.remitente.destinatarios[this.dataDestinatarioSelected.id]
    );
  },
  ionViewDidEnter() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.header = this.$refs["myHeader_destinatariosDetalle"].offsetHeight;
        this.footer = this.$refs["footer_destinatariosDetalle"].offsetHeight;
        this.cont_visible = true;
        this.contenido_destinatariosDetalle = contenido_style(
          this.heigthScreen,
          this.header,
          this.footer,
          this.plataforma == "web" ? 63 : 0
        );
        this.blur_sup_destinatariosDetalle = blur_sup_style(this.header);
        this.blur_inf_destinatariosDetalle = blur_inf_style(this.footer);
      }, 100);

      setTimeout(() => {
        if (
          this.$refs["contenido_destinatariosDetalle"].scrollHeight >
          this.$refs["contenido_destinatariosDetalle"].clientHeight
        ) {
          this.scroll_to_bottom = false;
        }
      }, 100);
    });
  },
  ionViewWillLeave() {
    // console.log("bye");
  },
};
</script>

<style>
.destinatariosDetalleTitle {
  margin-top: 1.35rem;

  color: #801b1c;
}
.destinatariosDetalleBody {
  margin-top: 1rem;
}
.destinatariosDetalleEncabezado1_line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.destinatariosDetalleEncabezado1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.5px;

  color: #614ad3;
}
/* .destinatariosDetalleEncabezado2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.857033px;
  text-decoration-line: underline;
  color: #614ad3;
} */
.destinatariosDetalleRow {
  margin-top: 0.9rem;
}
/* .destinatariosDetalleItem {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #801b1c;
} */

/* .destinatariosDetalleData {
  margin-top: 0.25rem;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.5px;
  color: #787878;
} */
.destinatariosDetalleHr {
  border: 1px solid #787878;
  margin-top: 0.95rem;
  margin-bottom: 1.25rem;
}
/* .destinatariosDetalleEncabezado3 {
  margin-top: 0.9375rem;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.5px;
  color: #614ad3;
} */
.destinatariosDetalleBody2 {
  margin-top: 0.4rem;
  /* height: 7rem; */
  overflow-y: auto;
  /* border: 1px solid green; */
}

.destinatariosDetalleRow2 {
  /* margin-top: 0.4rem; */
  /* border: 1px solid blue; */
  padding-bottom: 0.4rem;
}
.destDetNuevoMedioEntrega {
  margin-top: 1.625rem;
  text-align: center;
  text-transform: uppercase;
  color: #614ad3;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}
.desti_detayl_icon {
  /* width: 5rem; */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 0.5rem;
  /* border: 1px solid green; */
  padding: 0.2rem 0.3rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-left: 2rem;
}
.desti_detayl_icon_rigth {
  margin-left: 2rem;
}
.destinatariosDetalleEncabezado1_icons {
  display: flex;
  flex-direction: row;
  width: fit-content;
}
</style>