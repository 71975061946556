<template>
  <div class="cardMisDatMedioEntrega">
    <div class="cardMisDatMedioEntregaItem">
      <div class="cardMisDatMedioEntregaTipo" @click="toggleDesplegado">{{ tipo }}</div>
      <div class="cardMisDatMedioEntregaDet" v-if="desplagado">
        {{ detalle1 }}
      </div>
      <div class="cardMisDatMedioEntregaDet" v-if="desplagado">
        {{ detalle2 }}
      </div>
      <div class="cardMisDatMedioEntrega_options" v-if="desplagado">
        <div
          class="cardMisDatMedioEntrega_edit"
          @click="callEditFormaEntregaForm"
        >
          <editar_eliminar
            type="editar"
            formato="icon"
            ancho_img="1.2"
            font_size_text="10"
          />
        </div>
        <div
          class="cardMisDatMedioEntrega_delete"
          @click="call_delete_FormaEntrega"
        >
          <editar_eliminar
            type="eliminar"
            formato="icon"
            ancho_img="1.4"
            font_size_text="10"
          />
        </div>
      </div>
    </div>
    <div class="cardMisDatMedioEntregaCheckBox" @click="toggleDesplegado">
      <img :src="iconCheckBoxOff" alt="" srcset="" v-if="!desplagado" />
      <img :src="iconCheckBoxOn" alt="" srcset="" v-else />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { useI18n } from "vue-i18n";
import editar_eliminar from "../generales/editar_eliminar.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  props: {
    medioEntrega: Object,
    idFEDesplegado: String,
  },
  components: {
    editar_eliminar,
  },
  data() {
    return {
      iconCheckBoxOff: require("../../assets/imgs/destinatarios/iconCheckBoxOff.svg"),
      iconCheckBoxOn: require("../../assets/imgs/destinatarios/iconCheckBoxOn.svg"),
      desplagado: false,
    };
  },
  computed: {
    ...mapState(["configuracion", "dataDestinatarioSelected"]),

    tipo() {
      let aux = "";
      if (this.medioEntrega && this.medioEntrega.forma_entrega_id != "") {
        if (this.medioEntrega.forma_entrega_id == 1) {
          if (
            this.medioEntrega &&
            this.medioEntrega.forma_entrega_id != "" &&
            this.configuracion &&
            this.dataDestinatarioSelected
          ) {
            aux = `${this.t(
              "app.cotizadorDestinatario.deposito"
            )} ${this.medioEntrega.bancos_nombre}` ;
          }
        } else if (this.medioEntrega.forma_entrega_id == 2){
          aux = `${this.t(
            "app.cotizadorDestinatario.entregaCaja")} ${this.medioEntrega.bancos_nombre}`;
        } else if (this.medioEntrega.forma_entrega_id == 6){
          aux = `${this.t(
              "app.cotizadorDestinatario.ewallet"
            )} ${this.medioEntrega.bancos_nombre}` ;
        }
      }
      if (this.medioEntrega.forma_entrega_id == 1) {
        let moneda_index = this.configuracion.monedas.findIndex((moneda) => {
          return moneda.id == this.medioEntrega.moneda_destino_id;
        });
        if (moneda_index > -1) {
          aux = `${aux} (${this.configuracion.monedas[moneda_index].sigla})`;
        }
      } else if (this.medioEntrega.forma_entrega_id == 2) {
        let moneda_index = this.configuracion.monedas.findIndex((moneda) => {
          return moneda.id == this.medioEntrega.moneda_caja;
        });
        if (moneda_index > -1) {
          aux = `${aux} (${this.configuracion.monedas[moneda_index].sigla})`;
        }
      }

      return aux;
    },
    detalle1() {
      let aux = "";
      if (
        this.medioEntrega &&
        this.medioEntrega.forma_entrega_id != "" &&
        this.configuracion &&
        this.dataDestinatarioSelected
      ) {
        if (this.medioEntrega.forma_entrega_id == 1) {
          let aux2 = Object.keys(
            this.configuracion.pais[
              this.dataDestinatarioSelected.pais_moneda_destino_id
            ].tipo_cuenta
          );
          let aux3 = aux2.findIndex((tipo) => {
            return (
              this.configuracion.pais[
                this.dataDestinatarioSelected.pais_moneda_destino_id
              ].tipo_cuenta[tipo][0] == this.medioEntrega.tipo_cuenta
            );
          });
          if (aux3 > -1) {
            aux = `${
              this.configuracion.pais[
                this.dataDestinatarioSelected.pais_moneda_destino_id
              ].tipo_cuenta[aux3][1]
            }`;
          }
        } else {
          aux = this.medioEntrega.bancos_nombre;
        }
      }
      return aux;
    },
    detalle2() {
      let aux = "";
      if (
        this.medioEntrega &&
        this.medioEntrega.forma_entrega_id != "" &&
        this.configuracion &&
        this.dataDestinatarioSelected
      ) {
        if (this.medioEntrega.forma_entrega_id == 1) {
          aux = `Cta. N° ${this.medioEntrega.numero_cuenta}`;
        }
      }
      return aux;
    },
  },
  watch: {
    idFEDesplegado() {
      if (this.idFEDesplegado != this.medioEntrega.id) {
        this.desplagado = false;
      }
    },
  },
  methods: {
    callEditFormaEntregaForm() {
      this.$emit("callEditFormaEntregaForm", this.medioEntrega.id);
    },
    call_delete_FormaEntrega() {
      this.$emit("delete_FormaEntrega", this.idFEDesplegado);
    },
    toggleDesplegado() {
      this.desplagado = !this.desplagado;
      this.$emit("changeFEDespliegue", this.medioEntrega.id);
    },
  },
  mounted() {
    // console.log(this.medioEntrega);
  },
};
</script>

<style>
.cardMisDatMedioEntrega {
  padding-top: 0.3375rem;
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  background: #ececec7a;
  padding: 0.3rem 0.4rem 0.3rem 0.6rem;
}
.cardMisDatMedioEntrega_edit{
  cursor: pointer;
  padding: 0.1rem 0.4rem;
}
.cardMisDatMedioEntrega_delete{
  cursor: pointer;
  padding: 0.1rem 0.4rem;
  margin-left: 5rem;
}
.cardMisDatMedioEntregaItem {
  width: 80%;
}
.cardMisDatMedioEntregaTipo {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1.0rem;
  letter-spacing: 1px;
  color: #801b1c;
  padding-bottom: 0.3rem;
}
.cardMisDatMedioEntregaDet {
  margin-top: 0.2rem;

  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.0rem;
  color: #4c4c4c;
}
.cardMisDatMedioEntrega_options {
  margin-top: 0.2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}
.cardMisDatMedioEntregaCheckBox {
  text-align: left;
  width: 15%;
  cursor: pointer;
}
.cardMisDatMedioEntregaCheckBox img {
  width: 1.25rem;
}
</style>