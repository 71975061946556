<template>
  <div class="cardCotizadorResItem">
    <!-- <div v-if="first_item">
      <div class="cardCotizadorRes_first_item">
        <div class="list_1_item">
          {{ t(`variables.reglasFormulario.Destinatario_${item[0]}`) }}
        </div>
        <div class="card_list_2_icon" @click="call_action">
          <editar_eliminar
            type="editar"
            formato="icon"
            ancho_img="0.8"
            font_size_text="10"
          />
        </div>
      </div>
      <div class="list_1_valor">{{ item[1] }}</div>
    </div>
    <div v-else-if="last_item">
      <div class="cardCotizadorRes_first_item">
        <div class="list_1_item">
          {{ t(`variables.reglasFormulario.Destinatario_${item[0]}`) }}
        </div>
        <div class="card_list_2_icon" @click="call_action">
          <editar_eliminar
            type="eliminar"
            formato="icon"
            ancho_img="1"
            font_size_text="10"
          />
        </div>
      </div>
      <div class="list_1_valor">{{ item[1] }}</div>
    </div> -->
    <div >
      <div class="list_1_item">
        {{ t(`variables.reglasFormulario.Destinatario_${item[0]}`) }}
      </div>
      <div class="list_1_valor">{{ item[1] }}</div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
// import editar_eliminar from "../generales/editar_eliminar.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },

  props: {
    item: Array,
    // first_item: Boolean,
    // tipo_action: String,
    // last_item: Boolean,
  },
  components: {
    // editar_eliminar,
  },
  data() {
    return {
      
    };
  },
  methods: {
    // call_action() {
    //   this.$emit("call_action_", this.tipo_action);
    // },
  },
  mounted() {
    // console.log(this.item);
  },
};
</script>

<style>
.cardCotizadorResItem {
  font-family: "Roboto";
  font-style: normal;
  letter-spacing: 0.5px;
}
.cardCotizadorResItemDestacado {
  font-weight: 700;
}
.cardCotizadorRes_first_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cotizadorResumenData1TitleEdit {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2rem;
  text-align: right;
  letter-spacing: 0.5px;
  text-decoration-line: underline;
  color: #614ad3;
}
.cotizadorResumenData1TitleBorrar {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2rem;
  text-align: right;
  letter-spacing: 0.5px;
  text-decoration-line: underline;
  color: var(--customA-801B1C);
}
.card_list_2_icon {
  width: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 0.5rem;
}
</style>